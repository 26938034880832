<script setup lang="ts">

</script>

<template>
    <section class="hero text-white dark-overlay bg-cover hero-blog flex-center mb-3 mb-lg-5">
        <div class="hero-blog dark-overlay flex-center w-100">
            <slot name="image" />
            <div class="card-img-overlay d-flex align-items-center">
                <div class="w-100 overlay-content container flex-center--column">
                    <slot />
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">

</style>